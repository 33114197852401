.input {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.input label {
  font-weight: bold;
  margin-right: 1rem;
  display: block;
}

.input input {
  margin: auto;
  width: 4rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
  
}

@media (max-width: 768px) {
  .input label {
    display: none;
  }

  
}