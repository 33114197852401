.meals {
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;

  animation: meals-appear 1s ease-out forwards;
}

.meals .titulos{
  text-align: center;
  margin: 0;
  padding: 0.3rem ;
  background-color: var(--cor-principal) ;
  color: var(--cor-principal-letra) ;
}

.meals ul {
  list-style: none;
  width: 95%;
  margin: auto;
  padding: 0;
}

@keyframes meals-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  .meals {
    margin: 0 auto;
  }
}

.aviso{
/*   color: white; */
  text-align: center;
}