.textotitulo{
    background-color: var(--cor-principal);
    color: white;
    text-align: center;
    margin: auto;
    padding: 0.5rem;
}

/* p{
    text-align: justify;
     text-indent: 2rem; 
    margin: 1rem;
} */

.texto{
    text-align: justify;
    text-indent: 2rem;
    margin: 1rem;
}


/* 
.sobre{
    position: absolute;
    top:6rem;
    margin: auto 1rem;
     padding: 1.5rem; 
    background-color: white;
} */

/* .sobre img{
    margin: auto;
    margin-bottom: 1rem;
    max-width: 500px;
} */
.gold { color: #FFD700; }
.silver { color: #C0C0C0; }
.bronze { color: #CD7F32; }

.sobre_div{
    position: absolute;
    top:6rem;
/*     margin: 0 auto; */
    /* min-height: calc(100vh - 6rem); */
/*     padding: 1.5rem; */
/*     margin: auto 1rem;
    background-color: white; */
/*     border-style: dotted; */
}

.sobre_section{
    /*     position: absolute;
        top:6rem; */
    
    /*     padding: 1.5rem; */
        margin: auto 1rem;
        background-color: white;
        padding-bottom: 1rem;
    }
    
    .sobre_div img{
        padding: 0 1rem;
        margin: auto;
        background-color: transparent;
        margin-bottom: 1rem;
        max-width: 500px;
    }