.nomeapp{
  padding: 0;
margin: auto auto auto 1rem;
color:  var(--cor-principal);
}

.navbar-menu section {
  display: flex;
  width: 85%;
}

.headerstyle {
  position: relative;
}

.navbar .button {
  border: 0;
  padding: 0;
  margin: auto;
  font-family: inherit;
  background: transparent;
  color:  var(--cor-principal);
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
/*   max-height: 1rem; */
}

.navbar .span {
  font-size: 1.6rem;
  max-height: 1.6rem;
}

.navbar {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  background: white;
  color: white;
  font-family: inherit;
  box-sizing: border-box;
  padding: 0 1rem;
}

.navbar_div_logo {
display: flex;
}

@media only screen and (min-width: 600px) {
  .navbar {
    justify-content: space-between;
    padding: 0 1rem;
  }
}

.navbar-overlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}


body.open .navbar-overlay {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 600px) {
  .navbar-overlay {
    display: none;
  }

}
.material-symbols-outlined{
/*   margin-right: 1rem;
  
  max-height: 1.6rem; */
  margin: auto 0.5rem auto auto;
  font-size: 2rem;
  color: black
} 


.navbar-burger {
  position: absolute;
  top: 0.5rem;
/*   right: 0.8rem; */
  /* display: grid; */
  place-items: center;
  width: 40px;

  padding: 0;
  color:   var(--cor-principal);
}


.navbar-burger-search {
  position: absolute;
  top: 0;
  right: 13%;
  display: grid;
  place-items: center;
  width: 5rem;
  height: 9rem;
  padding: 0;
}


@media only screen and (min-width: 600px) {

  .navbar-burger-search,
  .navbar-burger {
    display: none;
  }
   .material-symbols-outlined{
    margin-right: 24px;
    display: none;
  }

}

.navbar-title {
  position: absolute;
  top: 1rem;
  left: 0;
  margin-left: 5.6%;
  height: 1.8rem;
}

@media only screen and (max-width: 600px) {
  .navbar-title {
    margin-left: 3%;
    top: 0.5rem;
  }
}

.navbar-menu input {
  vertical-align: top;
  height: auto;
  margin-right: 5px;
  display: none;
}

.navbar-menu p,
.navbar-menu p:active,
.navbar-menu p:hover {
  background-color: black;
  text-decoration: none;
  color: white;
}

body.open .navbar-menu input {
  display: inline;
}

.navbar-menu {

  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  translate: -100% 0;
  width: 270px;
  height: 100%;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  background: white;
  color: black;
  visibility: hidden;
  transition: translate 0.3s;
}

.seta-menu {
  display: none;
}

body.open .seta-menu {
  display: block;
}

.navbar-img-menu {
  display: none;
}

@media only screen and (max-width: 600px) {
body.open .navbar-menu {
  translate: 0 0;
  visibility: visible;
  z-index: 7;
}
body.open .navbar-img-menu {
  display: block;
  margin: 0 auto 0rem auto;
}
}

@media only screen and (min-width: 600px) {



  .navbar-menu {
    position: relative;
    translate: -5% 0;
    width: auto;
    background: transparent;
    flex-direction: row;
    visibility: visible;
  }
}

@media only screen and (max-width: 599px) {
  .navbar-menu {
    left: 0;
  }
}

.navbar-menu button,
.navbar-menu button:active,
.navbar-menu button:hover {
  /* color: #a32121; */
  color: white;
  background: transparent;
  text-decoration: none;
  /* padding: 0 8px;
 */
  /*     margin-top: 4px; */
  /*     border-style: dotted; */
  /*margin-left: 24px; */
  /* 
    font-size: 1rem; */
  /* border-style: dotted; */
  /* align-items: bottom; */
}

@media only screen and (max-width: 599px) {
  .navbar-menu {
    left: 0;
  }

  .navbar .button {
    margin: 8px;
  }

}

.navbar-menu>button.active {
  color: black;
  text-decoration: overline 2px;
}

.App-banner-img {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  display: block;
}

.App-banner-img-media {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  display: none;
}

@media only screen and (max-width: 600px) {
  .App-banner-img {
    display: none;
  }

  .App-banner-img-media {
    display: block;
  }
}



.App-banner-tittle {
  position: absolute;
  top: 9rem;
  width: 100%;
  left: 5.6%;
  margin-left: 0%;
  z-index: 1;
  text-align: left;
}

.App-banner-tittle .titulo {
  font-size: 3.5rem;
  padding: 0px;
  margin: 0%;
  color: #3b3b3b;
  z-index: 1;
}

.App-banner-tittle .subtitulo {
  color: #676767;
  font-size: 1.6rem;
  padding: 0px;
  margin: 0%;
  z-index: 1;
}

.App-banner-tittle .botao {
  background-color: #27b523;
  color: white;
  border: 0;
  font-size: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .App-banner-tittle {
    padding: 0;
    position: absolute;
    /*     top: 5rem; */
    top: 20vw;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .App-banner-tittle .titulo {
    /*     font-size: 1.6rem; */
    font-size: 7vw;
    padding: 0px;
    margin: 0%;
    color: #3b3b3b;
    z-index: 1;
  }

  .App-banner-tittle .subtitulo {
    color: #676767;
    /*     font-size: 1.2rem; */
    font-size: 5vw;
    padding: 0px;
    margin: 0%;
    z-index: 1;
  }

  .App-banner-tittle .botao {
    background-color: #27b523;
    color: white;
    font-size: 4vw;
    /*     font-size: 1rem; */
    text-align: center;
    padding: 0.4rem;
    /*     margin: 2rem 0 0 0; */
    margin: 5vw 0 0 0;
    border-style: dotted;
  }
}

.material-symbols-outlined{
  color: black;

}