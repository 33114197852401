.meal {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  padding: 3% 0;
}

.meal h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}

.meal img {
  width: 100px;
  height: 100px;
}

.description {
  font-style: italic;
  font-size: 0.8rem;
}

.price {
  margin-top: 0.25rem;
  font-weight: bold;
  color: var(--cor-principal);;
  font-size: 1.25rem;
}

.add{
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
    
  margin:  auto 0.3rem auto 0;
}

.imgdrink{
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
    
  margin:  auto 0;
}

.namedrink{
  margin:  auto auto auto 8px;
}

.tituloproduto{
  text-align: center;
  background-color: var(--cor-principal);;
  color: black;
  /* border-top: 2rem solid black;   */
  padding: 0.5rem;
}

.tituloproduto1{
  text-align: center;
  background-color: var(--cor-principal);;
  color: black;
/*  border-top: 2rem solid black;   */
  padding: 0.5rem;
}

