.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: /* #000000 */ var(--cor-fundo);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
/*   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  z-index: 10;
}

.header {
  color:  var(--cor-principal);
}

.secondheader {
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100%;/* 
  height: 5rem; */
  background-color: var(--cor-fundo);
  color: white;


  display: flex;
  flex-wrap: wrap;

  justify-content: space-evenly; 
  align-items: center;

  padding: 0 5%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
.secondheader a{
  color: var(--cor-fundo-letra);
  text-decoration: none;
}
.secondheader a:hover,
.secondheader a:active{
  color: var(--cor-fundo-letra);
  text-decoration: underline;
}

 .secondheader p{
  text-align: center;
  margin: 10px;
} 

@media (max-width: 768px) {
  .input label {
    display: none;
  }

  
}

.main-image {
  width: 100%;
  height: 25rem;
  z-index: 0;
  overflow: hidden;
 }

.main-image img {
  width: 110%;
  height: 100%;
  object-fit: cover;/* 
   transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem); */
 }


 .material-icons-div{

  margin-right: 1rem;
  font-size: 2rem;
  max-height: 1.6rem;
  color: black;
}
/* button  */.navbar-burger {
  position: absolute;
  top: 0.5rem;
/*   right: 0.8rem; */
  /* display: grid; */
  place-items: center;
  width: 40px;
  /* width: 5rem;
 */
  padding: 0;
  color:   var(--cor-principal);
}

@media only screen and (min-width: 600px) {

  .material-icons-div{
    margin-right: 24px;
    display: none;
  }

}