.form {
  text-align: right;
}

.form Input{
  text-align: center;
}

.form button:hover,
.form button {
  -webkit-tap-highlight-color: transparent;
  font: inherit;
  cursor: pointer;
  background-color: var(--cor-principal);;
  /* border: 2px solid #000000; */
  border: none;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}



.form button:active {
  background-color: black;
  color: white/* 
  border-color: #000000; */
}

