.cart_div {
  position: absolute;
  top: 6rem;
  width: 100%;
}

.cart_section {
  margin: auto 1rem;
   background-color: transparent; 
   /* border-image: linear-gradient(to right, #FFFFFF, #F6F6F6) 1; */
  padding-bottom: 1rem;
  padding: 0 1rem ;
  min-height: calc(100vh - 25rem); 
/*   border-style: dotted;  */
}


.cart-items {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: auto;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.frete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0;
}

.actions {
  text-align: center;
  align-items: center;
  padding-bottom: 1rem;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: none; 
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  /* margin-left: 1rem; */
}

.actions button:hover,
.actions button:active {
  background-color: #363636;
  border-color: #363636;
  color: white;
}

.actions .button--alt {
  color: white;
  color: black;
  border: 1px solid black; 
}

.actions .button--addi {
  color: white;
  background-color: black;
  border: 1px solid black; 
}

.actions .button {
  background-color: black;
  color: white;
}

.erro{
  color:red;
}

.done{
  color:green;
  margin: 0;
  padding: 0;
}

.titulocart{
  border: 2px solid  var(--cor-principal);
  color: white;
  background-color:  var(--cor-principal);
  margin: 0 auto 1rem auto;
  padding: 0.3rem 0;
  text-align: center;
}

.carrovazio{
  text-align: center;
  color: rgb(63, 63, 63);
}

.titulocartentrega{
  border: 2px solid  var(--cor-principal);
  color: white;
  background-color:  var(--cor-principal);
  margin: 0 auto 1rem auto;
  padding: 0.3rem 0;
  text-align: center;
}