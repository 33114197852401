/* Estilo para o contêiner do formulário */
.form-container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Estilo para os rótulos dos campos */
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Estilo para os campos de entrada de texto */
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Estilo para o botão de envio */
  button {
    background-color: #007bff; /* Cor do botão */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Estilo para o botão de envio quando passar o mouse sobre ele */
  button:hover {
    background-color: #0056b3; /* Cor mais escura quando hover */
  }

  #formaPagamento {
    width: 100%;
    padding: 10px;
    /* margin-bottom: 20px; */
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  #tipoStatus {
    width: 100%;
    padding: 10px;
    /* margin-bottom: 20px; */
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .verifique{
    color: red;
  }