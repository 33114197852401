.textotitulo{
    background-color: var(--cor-principal);
    color: white;
    text-align: center;
    margin: auto;
    padding: 0.5rem;
}

.texto{
    text-align: justify;
    text-indent: 2rem;
    margin: 1rem;
}

.funcionarios_div{
    position: absolute;
    top:6rem;
}

.funcionarios_section{
    margin: auto 1rem;
    background-color: white;
    padding-bottom: 1rem;
}

.funcionarios_div img{
    padding: 0 1rem;
    margin: auto;
    background-color: transparent;
    margin-bottom: 1rem;
    max-width: 500px;
}

.AppFormFuncionario {
    /* border-style: dotted; */ 
    width: 350px;
    position:relative;
    margin: 2rem auto;
  /*   top: 40%;
    left: 50%;
    padding: 0% 2%;
    transform: translate(-50%, -50%); */
  }
  