.stepper {
    margin: auto auto 0.5rem auto;
    width: 100%;
    padding: 0.5rem 0;
    font-family: Arial, sans-serif;
/*     background-color: white; */
  }
  
  .stepper-labels {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  
  .step {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .step-label {
    padding: 12px;
    background-color: #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    z-index: 1;
  }
  
  .step.active .step-label {
    background-color: black;
  }
  
  .svg {
    filter: invert(100%) brightness(1000%);
  }
  
  .step.completed .step-label {
    background-color: black;
  }
  
  .step::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #ccc;
    z-index: 0;
    transform: translateY(-50%);
  }
  
  .step:first-child::before {
    width: 0; /* Oculta a linha à esquerda do primeiro passo */
  }
  
  .step:nth-child(1)::before {
    left: 50%;
    width: calc(50% + 16px); /* Ajuste para garantir que a linha se estenda até o meio do próximo círculo */
  }
  
  .step.active::before,
  .step.completed::before {
    background-color: black;
  }
  
  .step:last-child::before {
    width: 50%;
    /* display: none; */ /* Oculta a linha após o último passo */
  }
  
  .stepper-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .stepper-controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  