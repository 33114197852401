.title {
  color: /* #007bff */var(--cor-principal);
  text-align: center;
}

.login button:active,
.login button:hover,
.login button {
/*   margin: 0;
  margin-top: 10px;
  margin-bottom: 5px; */
  margin: 1rem auto 1rem auto;
  background-color: var(--cor-principal);
}

.login {
  padding: 0;
  padding: 20px;
  margin: auto;
  border-radius: 10%;
  /*   border-style: dotted; */
  background-color: white /* rgba(255, 255, 255, 0.5) */;
}

.login input {
  margin-top: 10px;
  /* 
  background-color: #450000; 
  color: white;*/
}

.login input:active,
.login input:focus {
  margin-top: 10px;
  /* 
  background-color: #450000; 
  color: white;*/
}

.buttonlogin {
  background-color: #4CAF50;
  border-radius: 4px;
  border: none;
  color: white;
  margin: 0px;
  margin-top: 20px;
  padding: 0;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.buttonlogin:disabled {
  background-color: #808080;
  border-radius: 4px;
  border: none;
  color: white;
  margin: 0px;
  margin-top: 20px;
  padding: 0;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}