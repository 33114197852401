.slideshow-container {
    max-width: 60rem;
    position: relative;
    margin: auto;
/*     border-style: dotted; */
  }
  
  .mySlides {
    display: none;
  }
  
  .mySlides.show {
    display: block;
  }
  
  img {
    width: 100%;
    display: block; /* Adicionado para remover espaço embaixo da imagem */
    object-fit: cover; /* Garante que a imagem cubra completamente o contêiner */
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px; /* Ajuste conforme necessário */
    height: 30px; /* Ajuste conforme necessário */
    padding: 0px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    outline: none;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .prev:hover, .next:hover,
  .prev:active, .next:active {
    background-color: rgba(0, 0, 0, 0.7);
    outline: none;
  }