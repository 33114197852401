.exibir {
    position: absolute;
    top: 4.5rem;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-around;
    width: 100%;
    overflow: scroll;
}

.exibirprodutos {
    position: absolute;
    top: 4.5rem;
    padding: 1rem 2rem;
    display: block;
    margin: auto;
    /*     gap: 20px; */
    flex-wrap: wrap;

    justify-content: space-around;
    width: 100%;
    overflow: scroll;
}

.exibirrelatorios {
    position: absolute;
    top: 4.5rem;
    display: block;
    margin: auto;
    /*     gap: 20px; */
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    /* justify-content: space-around; */
    width: 100%;
    overflow: scroll;
    justify-content: center;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.exibirnome {
    text-align: left;
}

.exibirrelatorios table {
    margin: auto;
}

.exibirrelatorios h1,
.exibirprodutos h1 {
    color: black;
    /*     padding: 0; */
    margin: 1rem auto;
    padding: 0px;
}

.exibirprodutos ul {
    margin: auto;
}

.exibirprodutos li {
    margin-bottom: 1rem;
}

.exibirprodutos .divbutton {
    width: 100%;
    display: flex;
}

.exibirprodutos .divbutton button:hover,
.exibirprodutos .divbutton button:active,
.exibirprodutos .divbutton button {
    margin: 1rem auto;
    background-color: var(--cor-principal);
}

.escolherStatus {
    position: absolute;
    width: 100%;
    top: 5.6rem;
    display: flex;
    justify-content: space-evenly;
}

.show {
    display: block;
}

.hide {
    display: none;
}