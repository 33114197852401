.textotitulo{
    background-color: var(--cor-principal);
    color: white;
    text-align: center;
    margin: auto;
    padding: 0.5rem;
}

.texto{
    text-align: justify;
    text-indent: 2rem;
    margin: 1rem;
}

.contato_div{
    position: absolute;
    top:6rem;
}

.contato_section{
    margin: auto 1rem;
    background-color: white;
    padding-bottom: 1rem;
}

.contato_div img{
    padding: 0 1rem;
    margin: auto;
    background-color: transparent;
    margin-bottom: 1rem;
    max-width: 500px;
}