/* Estilização básica para o card de pedidos */
.pedidocard {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    margin: auto auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pedidocard h2 {
    font-size: 24px;
    margin: 0;
    text-align: center;
  }

  .pedidocard .h2blue {
    font-size: 24px;
    margin: 0;
    background-color: #007bff;
    color: #fff;
  }
  .pedidocard .h2red {
    font-size: 24px;
    margin: 0;
    background-color: red;
    color: #fff;
  }
  .pedidocard .h2green {
    font-size: 24px;
    margin: 0;
    background-color: green;
    color: #fff;
  }
  
  .pedidocard p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .pedidocard h3 {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .pedidocard ul {
    list-style: none;
    padding: 0;
  }
  
  .pedidocard li {
    font-size: 16px;
    margin: 5px 0;
  }
  
  /* Estilização para o botão "Enviar" (se necessário) */
  .pedidocard button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .pedidocard button:hover {
    background-color: #0056b3;
  }

  .botoes{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .botoes .red,
  .botoes .red:hover{
    width: 40%;
    background-color: red;
  }

  .botoes .green,
  .botoes .green:hover{
    width: 40%;
    background-color: green;
  }

  .botoes .green:active,
  .botoes .red:active{
    background-color: black;

  }