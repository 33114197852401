/*  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');  */

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: 'Noto Sans JP';
  src: url('./fonts/Noto_Sans_JP/static/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

html {
  /* font-family: Arial, sans-serif;  */
  font-family: Arial, Helvetica, sans-serif;
/*     font-family: 'Roboto' 'Noto Sans JP'  , sans-serif;   */ 
  --cor-fundo: #F6F6F6;
  --cor-fundo-letra: #000000;
  --cor-principal: /* #A42A25 */ #CD222B ;
  --cor-principal-clique: #000000;
  --cor-principal-letra: #FFFFFF;
}

body {
  margin: 0;
  background-color:  var(--cor-fundo); /* #3f3f3f */;
}

a,
button{
  -webkit-tap-highlight-color: transparent;
}

input:focus {
  /* outline: none; */
  outline: 2px solid black;
  /* border-color: #4CAF50; */ /* Cor da borda quando o input está selecionado */
}
