.footer {
    background-color: var(--cor-principal);
    color: white;
/*      position: absolute;
    left: 0;
    bottom: 0;  */
    width: 100%;
    padding: 0.5rem;
/*     padding: 20px; */
    text-align: center;
    margin: 1rem auto 0rem auto;

/*     border-top: 1px solid #e7e7e7; */
}
.footer a:hover,
.footer a:active,
.footer a{
    color: white;
}

.texto_footer{
    text-align: justify;
    text-indent: 2rem;
    margin: 1rem;
}

.footer button{
    background-color: transparent;
    color: white;
    font-size: 1rem ;
}