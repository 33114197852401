.button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: var(--cor-principal) ; /* #FFD700 */
  color: white;
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.button:hover{
  background-color: var(--cor-principal);
}
.button:active {
  background-color: black;
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.5rem;
}

.badge {
  background-color: #ffffff;
  padding: 0.1rem 0.5rem;
  border-radius: 25px;
  margin-left: 1rem;
  font-weight: bold;
  color: var(--cor-principal);
}

.button:hover .badge,
.button:active .badge {
  background-color: #ffffff;
}

.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}


.yourcart {
  display: block; /* Por padrão, a div é visível. */
}

@media (max-width: 768px) {
  .yourcart {
    display: none; /* Ocultar a div em telas pequenas. */
  }
  .badge {
    margin-left: 0rem;
  }
}