.summary {
  text-align: center;
  max-width: 70%/* 45rem */;
  width: 100%;
  margin: auto;
  --tamanho-rem: 5rem;
  --tamanho-px: 1rem;
  --resultado: calc(var(--tamanho-rem) + var(--tamanho-px));
  margin-top: var(--resultado);
  position: relative;
  background-color: var(--cor-fundo) ;
  color: var(--cor-fundo-letra);
  /* border-radius: 14px; */
  /* padding: 1rem; */
  /* box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25); */
}

.summary img{
width: 100%;
}

.summary h2 {
  font-size: 2rem;
  margin-top: 0;
}

.summary .frase1{
  background-color: #FFFFFF ;
  color: black/* var(--cor-principal) */;
  margin: auto 0.5rem;
  padding: 0.5rem;
  font-size: larger;
   font-weight: 700;
   border: 0.2rem;
   border-color: black/* var(--cor-principal) */;
   border-style:solid;
}

.summary .frase2{
  background-color: black/* var(--cor-principal) */ ;
  color: var(--cor-principal-letra) ;
  margin: auto 0.5rem 1rem 0.5rem;
  padding: 0.5rem 0.5rem 0.7rem 0.5rem;
  font-weight: 700;
  font-size: medium;
}

.summary input{
/*   background-color: var(--cor-principal) ;
  color: var(--cor-principal-letra) ; */
  margin: 1rem 0;
  padding: 0.5rem 2rem; 
  width: 100%;
/*   width: calc(100% - 1rem); */
  max-width: 100%;
  font-size: small;
}

@media (max-width: 768px) {
  .summary {
    --tamanho-px: 0rem;
    max-width: 100%/* 45rem */;
  }

  .summary input{
    /*   background-color: var(--cor-principal) ;
      color: var(--cor-principal-letra) ; */
      margin: 1rem 0.5rem;
      width: calc(100% - 1rem);
      max-width: 90%;
    }
  
}
