.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid black;
  padding: 1rem 0;
  margin: 0rem 0;
}

.cart-item h4 {
  margin: 0 0 0.5rem 0;
  color: #363636;

}

.cart-item h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.summary {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.price {
  font-weight: bold;
  color: var(--cor-principal);
}

.amount {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.1rem 0.5rem;
  border-radius: 6px;
  color: #363636;
}

.actions {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .actions {
    flex-direction: row;
  }
}

.cart-item button,
.cart-item button:hover {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: black;
  border: 1px solid black;
  width: auto;

  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
  padding: 0rem 0.6rem;
}


.cart-item button:active {
  background-color: black;
  color: white;
}